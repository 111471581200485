<template>
  <!-- 发货统计 -->
  <div class="home">
    <div class="searchNav">
      <el-button class="button" icon="el-icon-document" type="primary" style="margin-left: 0" :loading="addloading"
        @click="downloadFile">数据导出</el-button>

      <div class="searchParams">
        <div>
          <radioGroup @radioChange="radioChange" @complete="radioComplete" />
        </div>
      </div>
    </div>
    <div class="searchNav">
      <div class="searchParams" style="justify-content: flex-start">
        <el-input style="margin-left: 0" class="input1" v-model="queryParam.Condition" placeholder="请输入客户名称"></el-input>
        <el-input class="input1" v-model="queryParam.Keys" placeholder="请输入品名/服务"></el-input>
        <el-input class="input1" v-model="queryParam.keyword" placeholder="请输入回执编号"></el-input>
        <el-date-picker v-model="time" type="daterange" style="width: 250px; margin-left: 10px" start-placeholder="开始日期"
          end-placeholder="结束日期" value-format="yyyy-MM-dd" @change="changeTime">
        </el-date-picker>
        <el-select class="input1" v-model="queryParam.Status" placeholder="请选择状态">
          <el-option value="0" label="待签收"></el-option>
          <el-option value="1" label="已签收"></el-option>
          <el-option value="2" label="已拒收"></el-option>
          <el-option value="3" label="有差异"></el-option>
          <el-option value="4" label="已作废"></el-option>
        </el-select>

        <el-button class="button" @click="Getdata">查询</el-button>
      </div>
    </div>
    <el-table ref="multipleTable" :data="tableData" style="width: 100%" :max-height="height" v-loading="loading"
      @selection-change="handleSelectionChange">
      <el-table-column prop="cCusName" label="客户" width="200">
      </el-table-column>
      <el-table-column prop="BillId" label="回执编号" width="200">
      </el-table-column>
      <el-table-column prop="cInvName" label="品名/服务" width="200">
      </el-table-column>
      <el-table-column prop="CreateTime" label="日期">
        <template slot-scope="scope">
          {{ scope.row.CreateTime.substring(0, 10) }}
        </template>
      </el-table-column>
      <el-table-column prop="StatisticalQuantity" label="发货数量" width="100" align="right">
        <template slot-scope="scope">
          {{ fun2(scope.row.StatisticalQuantity) }}
        </template>
      </el-table-column>
      <el-table-column prop="QsQuantity" label="签收数量" width="100" align="right">
        <template slot-scope="scope">
          {{ fun2(scope.row.QsQuantity) }}
        </template>
      </el-table-column>
      <el-table-column prop="StatisticalWeight" label="发货重量" width="100" align="right">
        <template slot-scope="scope">
          {{ fun3(scope.row.StatisticalWeight) }}
        </template>
      </el-table-column>
      <el-table-column prop="QsWeight" label="签收重量" width="100" align="right">
        <template slot-scope="scope">
          {{ fun3(scope.row.QsWeight) }}
        </template>
      </el-table-column>
      <el-table-column prop="StatisticalPrice" label="发货金额" width="100" align="right">
        <template slot-scope="scope">
          {{ fun2(scope.row.StatisticalPrice) }}
        </template>
      </el-table-column>

      <el-table-column prop="QsPrice" label="签收金额" width="100" align="right">
        <template slot-scope="scope">
          {{ fun2(scope.row.QsPrice) }}
        </template>
      </el-table-column>
    </el-table>
    <div style="display: flex">
      <el-pagination layout="total,prev, pager, next,sizes" :total="pagination.total" :current-page="pagination.current"
        :page-size="pagination.pageSize" :page-sizes="pagination.pageSizeOptions"
        style="margin-top: 8px; padding: 0; flex: 1" @current-change="currentChange" @size-change="handleSizeChange">
      </el-pagination>
      <el-row :gutter="20" style="width: 450px; text-align: right; font-weight: bold">
        <el-col :span="8">
          <div>发货总数量</div>
          <div>{{ fun2(this.StatisticalQuantity) }}</div>
        </el-col>
        <el-col :span="8">
          <div>发货总重量</div>
          <div>{{ fun3(this.StatisticalWeight) }}</div>
        </el-col>
        <el-col :span="8">
          <div>发货总金额</div>
          <div>{{ fun2(this.StatisticalPrice) }}</div>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="width: 450px; text-align: right; font-weight: bold">
        <el-col :span="8">
          <div>签收总数量</div>
          <div>{{ fun2(this.QsQuantity) }}</div>
        </el-col>
        <el-col :span="8">
          <div>签收总重量</div>
          <div>{{ fun3(this.QsWeight) }}</div>
        </el-col>
        <el-col :span="8">
          <div>签收总金额</div>
          <div>{{ fun2(this.QsPrice) }}</div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import radioGroup from "@/components/CompanyRadioGroup.vue";
export default {
  name: "Home",
  components: {
    radioGroup,
    // waybillForm
  },
  data() {
    return {
      activeName: "first",
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "Id",
        order: "desc",
      },
      tableData: [],
      queryParam: { SupplieId: "全部", },
      keyword: "",
      loading: false,
      dialogVisible: false,
      templateId: "",
      templateList: [],
      tabList: [{ label: "待签收", name: "first" }],
      ip: "",
      selectRows: [],
      StatisticalQuantity: 0,
      StatisticalWeight: 0,
      StatisticalPrice: 0,
      QsQuantity: 0,
      QsWeight: 0,
      QsPrice: 0,
      row: {},
      addloading: false,
      time: null,
    };
  },
  mounted() { },
  computed: {
    height() {
      return document.documentElement.clientHeight * 0.75;
    },
  },
  methods: {
    // 下载文件
    downloadFile() {
      this.addloading = true;
      axios({
        method: "post",
        url: this.$baseUrl + "/BO/BilllistStatistical/ExportToExcel",
        data: { Search: this.queryParam },
        responseType: "blob",
      }).then((res) => {
        if (res.status == 200) {
          const url = URL.createObjectURL(res.data);
          const link = document.createElement("a");
          document.body.appendChild(link);
          link.download = "统计报表" + ".xlsx";
          link.href = url;
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        }
        this.addloading = false;
      });
    },
    changeTime(e) {
      console.log(e);
      if (e) {
        this.queryParam.StartDate = e[0];
        this.queryParam.EndDate = e[1];
      } else {
        this.queryParam.StartDate = "";
        this.queryParam.EndDate = "";
      }
    },
    //数量,金额保留两位小数
    fun2(val) {
      return Number(val).toFixed(2);
    },
    //重量保留三位位小数
    fun3(val) {
      return Number(val).toFixed(3);
    },
    timestampToTime(timestamp) {
      // 时间戳为10位需*1000，时间戳为13位不需乘1000
      var date = new Date(timestamp - 28800000);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    // 查看
    handleView(row) {
      console.log(row.Id);
      let url = `${location.href.split("#")[0]}#/SendSMS?prop=${row.BillId
        }&TimeStamp=${row.TimeStamp}`;
      window.open(url, "_blank");
    },
    // 多选
    handleSelectionChange(rows) {
      this.selectRows = rows.map((item) => item.evidenceId);
    },
    radioComplete(e) {
      console.log(e);
      if (e) {
        this.queryParam.SupplieId = e;
        this.getDataList();
        this.GetBillliSum();
      }
    },
    isRender(row) {
      if (!row.evidenceId) {
        return false;
      } else return true;
    },
    // 公司切换
    radioChange(e) {
      if (e == "more") return;
      this.queryParam.SupplieId = e;
      this.getDataList();
      this.GetBillliSum();
    },
    // 页签切换
    handleClick(tab, event) {
      this.pagination = {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      };
      this.queryParam.Status = this.activeName + "";
      if (this.activeName == "first") this.queryParam.Status = "";
      this.getDataList();
    },
    currentChange(page) {
      this.pagination.current = page;
      this.getDataList();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getDataList();
    },
    Getdata() {
      this.getDataList();
      this.GetBillliSum();
    },
    getDataList() {
      this.loading = true;
      let usr = JSON.parse(sessionStorage.getItem("userInfo"));
      console.log(usr.UserPhone);
      this.$http
        .post("/BO/BilllistStatistical/GetDataList", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || "Id",
          SortType: this.sorter.order,
          Search: this.queryParam,
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success) {
            this.tableData = resJson.Data;
            if (this.tableData) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          }
        });
    },
    GetBillliSum() {
      this.loading = true;
      this.$http
        .post("/BO/BilllistStatistical/GetBillliSum", {
          Search: this.queryParam,
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success) {
            this.StatisticalQuantity = resJson.Data.StatisticalQuantity;
            this.StatisticalWeight = resJson.Data.StatisticalWeight;
            this.StatisticalPrice = resJson.Data.StatisticalPrice;
            this.QsQuantity = resJson.Data.QsQuantity;
            this.QsWeight = resJson.Data.QsWeight;
            this.QsPrice = resJson.Data.QsPrice;
            console.log(
              "总数:" +
              this.StatisticalQuantity +
              " 总重:" +
              this.StatisticalWeight +
              " 总价钱:" +
              this.StatisticalPrice
            );
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.searchNav .el-button {
  background: #3362ec;
  border-color: #3362ec;
  color: #fff;
}

/deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner {
  color: #fff;
  background-color: #3362ec;
  border-color: #3362ec;
  box-shadow: -1px 0 0 0 #3362ec;
}

/deep/.el-radio-button__inner:hover {
  color: #3362ec;
}

/deep/ .el-tabs__nav {
  width: 100%;
  background: #fff;
}

/deep/ .el-tab-pane {
  width: 100%;
  background: #fff;
}

/deep/ .el-tabs__item {
  width: 16.6%;
  text-align: center;
  font-weight: bold;
  padding: 0;
}

/deep/ .el-tabs__item:hover {
  background: #ecf5ff;
}

.input1 {
  width: 200px;
  margin-left: 10px;
}
</style>
